<template>
  <q-card class="card text-center" flat>
    <q-card-section>
      <img src="../assets/logo.svg" />
      <h4 class="welcome"> Welcome Back </h4>
      <h6 style="margin:0">To Portal Access</h6>
    </q-card-section>
    <q-card-section>
      <form @submit.prevent="authenticate">
          <q-field class="email" icon="email" type="text" label="" helper=""
                   error-message="We need a valid email or mobile number">
              <q-input v-model="form.identifier" label="Email or Mobile" outlined required="required">
                <template v-slot:prepend>
                  <q-icon name="email" color="light-blue-5" />
                </template>
              </q-input>
          </q-field>
          <br>
          <q-field class="password" icon="lock" label="" helper=""
                   error-message="Write a password">
              <q-input :type="isPwd ? 'password' : 'text'" v-model="form.password" label="Password" outlined required="required">
                <template v-slot:prepend>
                  <q-icon name="lock" color="light-blue-5" />
                </template>
                <template v-slot:append>
                  <q-icon  :name="isPwd ? 'visibility_off' : 'visibility'"
                           class="cursor-pointer"
                           @click="isPwd = !isPwd" />
                </template>
              </q-input>
          </q-field>
          <span class="forgotPass cursor-pointer" clickable @click="navTo('forgot-password')">Forgot your password?</span>
          <br>
          <br>
          <div class="row justify-start items-center">
              <q-btn class="submitLogin full-width text-white" rounded
                     :disabled="processing" id="btn_log_in"
                     type="submit" size="lg">Log In To Portal
              </q-btn>
          </div>
      </form>
      <br>
      <br>
      <div class="row justify-center items-center">
          <span id="registerText">
              Don’t have a portal access?
              <a class="cursor-pointer" id="registerLink" @click="navTo('register')">Register</a>
          </span>
      </div>
      <br>
            <div class="row justify-center items-center">
          <span>
         Not yet a customer? Click <a class="link" href="https://www.moneyboat.co.uk/apply-now" target="_blank">here</a> to begin your application for a loan!
        </span>
      </div>
      <br>
            <div class="row justify-center items-center">
          <span>
         Warning: Late repayment can cause you serious money problems. For help go to <a class="link" href="https://www.moneyhelper.org.uk/en" target="_blank">moneyhelper.org.uk</a>
        </span>
      </div>
        <div class="row justify-center items-center">
              <span>
Representative APR 939.5%.
              </span>
      </div>

    </q-card-section>
  </q-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  meta: {
    title: 'Login'
  },
  data () {
    return {
      isPwd: true,
      processing: false,
      form: {
        identifier: this.$route.query.identifier,
        password: null
      }
    }
  },
  methods: {
    loginError () {
      this.$q.notify({
        message: 'The email/mobile or password you supplied do not match our records. Please check your information and try again. If you need to reset your password, please click "Forgot Your Password".',
        icon: 'lock',
        timeout: 3000,
        color: 'negative',
        textcolor: '#fff'
      })
    },
    async authenticate () {
      this.processing = true
      this.$q.loading.show({
        delay: 400
      })
      const { identifier, password } = this.form
      try {
        let authentication = await this.$oauth.login(identifier, password)
        let redirection = '/' // Default route
        if (this.$route.query.redirect && authentication) {
          redirection = this.$route.query.redirect
        }
        window.dataLayer.push({ event: 'logged_in' })
        this.$router.push(redirection)
      } catch (error) {
        // Error in Login
        this.loginError()
        this.$q.loading.hide()
        this.processing = false
      }
    },
    navTo (name) {
      if (this.$route.name !== name) {
        this.$router.push({ name })
      }
    },
    ...mapActions('users', [])
  }
}
</script>
<style lang="scss">
  .welcome {
    font-size:26px;
  }
.forgotPass {
  float: right;
  padding-right: 7px;
  color: #1c9edb;
  text-decoration: underline;
  padding-top: 5px;
}

.login-view form {
  max-width: 350px;
  margin:0 auto;
}
.submitLogin {
  background-image: linear-gradient(270deg, #3ccdef 0%, #1c9edb 100%);
}
#registerText {
  color: #3f3356;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
#registerLink, .link {
  color: #1c9edb;
  font-weight: 700;
  text-decoration: underline;
}
h4 {
  font-size: 24px;
  margin: 0rem 0rem;
  margin-top: -25px;
  color: #3f3356;
  font-weight: 700;
}
</style>
